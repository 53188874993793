@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: "Times New Roman", Times, serif;
}
